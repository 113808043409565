import { useResourcesHomeQuery } from "@/api/resources";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { resourcesRoute } from "./+route";

const searchSchema = object({
  category: fallback(optional(string()), undefined),
});
export const resourcesIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => resourcesRoute,
  loader: () => {
    useResourcesHomeQuery.prefetch();
  },
  validateSearch: valibotValidator(searchSchema),
  component: lazyRouteComponent(() => import("./+index.lazy")),
});
