import { useMeQuery } from "@/api/auth";
import { useMyLocationQuery } from "@/api/me";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { appRoute } from "../+route";

export const settingsRoute = createRoute({
  path: "settings",
  getParentRoute: () => appRoute,
  loader: () => {
    useMeQuery.prefetch();
    useMyLocationQuery.prefetch();
  },
  component: lazyRouteComponent(() => import("./+route.lazy")),
});

export const settingsIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => settingsRoute,
  component: lazyRouteComponent(() => import("./+index.lazy")),
});

export const claimsSettingsRoute = createRoute({
  path: "claims",
  getParentRoute: () => settingsRoute,
  component: lazyRouteComponent(() => import("./+claims.lazy")),
});

export const notificationsSettingsRoute = createRoute({
  path: "notifications",
  getParentRoute: () => settingsRoute,
  component: lazyRouteComponent(() => import("./+notifications.lazy")),
});
