import { useIsFirstRender, usePreviousValue } from "@givenwell/hooks";
import { CSS, keyframes, styled } from "@givenwell/stitches";
import { colors } from "@givenwell/theme";
import { HTMLAttributes } from "react";

export type SkeletonProps = HTMLAttributes<HTMLDivElement> & {
  css?: CSS;
  loaded?: boolean;
};

export function Skeleton({ loaded, ...props }: SkeletonProps) {
  const isFirstRender = useIsFirstRender();
  const wasPreviouslyLoaded = usePreviousValue(loaded);

  if (loaded) {
    return <Root {...props} data-instant={isFirstRender || wasPreviouslyLoaded ? "" : undefined} />;
  }
  return <AnimatedRoot {...props} />;
}

const fade = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const bgFade = keyframes({
  from: {
    borderColor: colors.transparent100,
    background: colors.transparent100,
  },
  to: {
    borderColor: colors.transparent200,
    background: colors.transparent200,
  },
});

const Root = styled("div", {
  animation: `${fade()} 0.4s`,
  "&[data-instant]": {
    animation: "none",
  },
});

const AnimatedRoot = styled("div", {
  boxShadow: "none",
  backgroundClip: "padding-box",
  cursor: "default",
  color: "transparent",
  pointerEvents: "none",
  userSelect: "none",
  "&::before, &::after, *": {
    visibility: "hidden",
  },
  animation: `1.2s linear infinite alternate ${bgFade()}`,
  opacity: 0.7,
  rounded: 2,
});
