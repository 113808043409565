import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import { StrictMode, Suspense, lazy } from "react";
import { queryClient } from "./api/utils";
import { router } from "./router";

const Toaster = lazy(async () => {
  // must use destructuring import to get tree shaking
  const { Toaster } = await import("@givenwell/components");
  return { default: Toaster };
});

export function App() {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Suspense fallback={null}>
          <Toaster />
        </Suspense>
        {import.meta.env.DEV && (
          <Suspense fallback={null}>
            <Devtools />
          </Suspense>
        )}
      </QueryClientProvider>
    </StrictMode>
  );
}

const Devtools = lazy(() => import("./Devtools"));
