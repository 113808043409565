import { useMeQuery } from "@/api/auth";
import { myWalletQueryOptions, useMyLocationQuery } from "@/api/me";
import { GivenwellLogoMark } from "@/components/GivenwellLogo";
import { LocationPickerModal } from "@/components/LocationPickerModal";
import { FlexLink } from "@/components/ui/Link";
import { useHasUnreads } from "@/hooks/useHasUnreads";
import { GivenwellTokenIcon } from "@/icons";
import { formatWholeNumber } from "@/utils/number";
import {
  Box,
  Center,
  Flex,
  Form,
  Input,
  QueryHandler,
  Skeleton,
  Span,
  Text,
  UnstyledButton,
} from "@givenwell/components";
import { colors } from "@givenwell/theme";
import { IconMapPinFilled, IconMenu, IconSearch } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import { MutableRefObject, useEffect, useState } from "react";
import { ModalDesktopSidebar, PersistentDesktopSidebar } from "./DesktopSidebar";

const clamp = (x: number, min: number, max: number) => Math.min(Math.max(x, min), max);

export function TopAppBar({
  scrollContainerRef,
  persistentSidebar,
}: {
  scrollContainerRef: MutableRefObject<HTMLElement>;
  persistentSidebar: boolean;
}) {
  const [open, setOpen] = useState(false);

  const search = useSearch({ strict: false });
  const { q, fullscreen } = search;

  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState(q || "");
  const [scrollY, setScrollY] = useState(0);

  const hasUnreads = useHasUnreads();

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;
    const handleScroll = () => {
      const scrollY = scrollContainer.scrollTop;
      setScrollY(scrollY);
    };

    scrollContainer.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [scrollContainerRef]);

  return (
    <>
      <Box
        data-fullscreen={fullscreen ? "" : undefined}
        css={{
          pt: "var(--safe-area-inset-top)",
          backgroundColor: "white",
          filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08))",
          zIndex: 1000,
          color: colors.blue800,

          display: "none",

          "&[data-fullscreen]": {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          },

          "@lg": {
            display: "block",
          },
        }}
        style={{
          transform: fullscreen ? `translateY(${clamp(scrollY - 100, -100, 0)}%)` : undefined,
        }}
      >
        <Flex css={{ height: 77, items: "center" }}>
          <Flex css={{ height: "100%", flex: "1 0 390px", items: "center", pl: 12 }}>
            <Box css={{ size: 48 }}>
              {persistentSidebar ?
                <Center css={{ size: "100%", cursor: "not-allowed" }}>
                  <IconMenu color={colors.gray200} />
                </Center>
              : <UnstyledButton
                  css={{ size: 48, display: "flex", items: "center", justify: "center", position: "relative" }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <IconMenu />
                  {hasUnreads ?
                    <Box
                      css={{
                        position: "absolute",
                        top: 10,
                        right: 8,
                        rounded: 10,
                        bg: colors.red500,
                        boxShadow: "0 0 0 2px #fff",
                        minW: "10px",
                        h: "10px",
                      }}
                    />
                  : null}
                </UnstyledButton>
              }
            </Box>
            <Box css={{ w: 4 }} />
            <Link to="/marketplace">
              <GivenwellLogoMark css={{ height: 32, transform: "translateY(-3px)" }} />
            </Link>
            <Box css={{ w: 16 }} />
            <LocationIndicator />
          </Flex>
          <Form
            onSubmit={() => {
              navigate({
                to: "/marketplace",
                search: {
                  ...search,
                  q: searchQuery,
                } as any,
              });
            }}
            css={{
              flex: "100 0 0px",
              maxW: 530,
            }}
          >
            <Input
              size="lg"
              placeholder="Search"
              value={searchQuery}
              onValueChange={value => {
                setSearchQuery(value);
              }}
              css={{
                "--rounded": "9999px",
                flex: "10 0 0px",
                maxW: 530,
              }}
              leftIcon={<IconSearch width="18px" height="18px" strokeWidth={2.667} color={colors.gray500} />}
            />
          </Form>
          <Flex css={{ height: "100%", flex: "1 0 390px", items: "center", pl: 20, justify: "flex-end" }}>
            <WalletBalance />
          </Flex>
        </Flex>
      </Box>
      {persistentSidebar ?
        <PersistentDesktopSidebar />
      : <ModalDesktopSidebar open={open} onOpenChange={setOpen} />}
    </>
  );
}

function WalletBalance() {
  const me = useMeQuery();
  const walletQuery = useQuery({ ...myWalletQueryOptions, enabled: me.data?.roles.includes("member") || false });

  if (!me.data?.roles.includes("member")) {
    return <div />;
  }

  return (
    <QueryHandler
      query={walletQuery}
      success={({ data: wallet }) => (
        <FlexLink
          css={{
            color: colors.blue800,
            gap: 8,
            px: 18,
            items: "center",
          }}
          to="/wallet"
        >
          <GivenwellTokenIcon />
          <Text css={{ weight: 500, fontScale: "2xl" }}>{formatWholeNumber(wallet.token_balance)}</Text>
        </FlexLink>
      )}
      empty={() => <div />}
      pending={() => (
        <Flex css={{ gap: 8, px: 18, items: "center", color: colors.blue800 }}>
          <GivenwellTokenIcon />
          <Skeleton>1000</Skeleton>
        </Flex>
      )}
      error={() => <div />}
    />
  );
}
function LocationIndicator() {
  const query = useMyLocationQuery();

  const [locationModalOpen, setLocationModalOpen] = useState(false);

  return (
    <QueryHandler
      query={query}
      success={({ data: location }) => {
        let placeName = location?.place_name || "";
        placeName = placeName.replace(/\d{4},/, ",");
        const placeNameParts = placeName.split(/\s*,\s*/);
        placeName = placeNameParts.slice(-3, -2).join(", ") || placeNameParts.slice(-2, -1).join(", ") || placeName;
        return (
          <>
            <UnstyledButton
              onClick={() => setLocationModalOpen(true)}
              css={{
                h: 48,
                display: "flex",
                items: "center",
                gap: 4,
                px: 12,
                width: 170,
                textAlign: "left",
              }}
            >
              <IconMapPinFilled style={{ minWidth: 24 }} />
              <Span css={{ weight: 700, fontScale: "xs", lineClamp: 1 }}>{placeName}</Span>
            </UnstyledButton>
            <LocationPickerModal
              locationName={location?.place_name || "Set a Location"}
              locationCoords={location ? { lng: location.longitude, lat: location.latitude } : undefined}
              open={locationModalOpen}
              onOpenChange={setLocationModalOpen}
              countryCode={location?.country_code || "NZ"}
            />
          </>
        );
      }}
      pending={() => <div />}
    />
  );
}
