import { createGlobalState } from "@givenwell/hooks";

const LOCAL_STORAGE_KEY = "has-visited-claims";
const initialValue = localStorage.getItem(LOCAL_STORAGE_KEY) === "true";

export const hasVisitedClaimsState = createGlobalState(initialValue);

hasVisitedClaimsState.subscribe(() => {
  localStorage.setItem(LOCAL_STORAGE_KEY, hasVisitedClaimsState.getValue().toString());
});
