import { createRoute, lazyRouteComponent, redirect } from "@tanstack/react-router";
import { claimsRoute } from "./+route";
import { hasVisitedClaimsState } from "./help/state";

export const claimsIndexRoute = createRoute({
  getParentRoute: () => claimsRoute,
  path: "/",
  beforeLoad: () => {
    if (!hasVisitedClaimsState.getValue()) {
      throw redirect({
        to: "/claims/help",
        mask: {
          to: "/claims",
        },
      });
    }
  },
  component: lazyRouteComponent(() => import("./+index.lazy")),
});
