import {
  ApiError,
  BookingListingVariant,
  GetListingResponse,
  PostListingEnquiryMessageBody,
  ProductListingVariant,
  SubscriptionListingVariant,
} from "@givenwell/marketplace-api";
import { queryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { notFound } from "@tanstack/react-router";
import { api, queryClient } from "./utils";

export function useListingCategoriesQuery() {
  return useQuery({
    queryKey: ["listing-categories"],
    queryFn: async () => await api.listings.getListingCategories(),
  });
}

export const listingQuery = (listingId: string) => ({
  queryKey: ["listings", listingId],
  queryFn: async () => {
    try {
      return await api.listings.getListing({ listingId });
    } catch (e) {
      if (e instanceof ApiError && e.status === 404) {
        throw notFound();
      }
      throw e;
    }
  },
});
export function useListingQuery(listingId: string) {
  return useQuery(listingQuery(listingId));
}
useListingQuery.prefetch = (listingId: string) => {
  return queryClient.prefetchQuery(listingQuery(listingId));
};

export type Listing = GetListingResponse;
export type Variant = GetListingResponse["variants"][number];

export function isBookingVariant(variant: Variant): variant is BookingListingVariant {
  return variant.type === "booking";
}
export function isProductVariant(variant: Variant): variant is ProductListingVariant {
  return variant.type === "product";
}
export function isSubscriptionVariant(variant: Variant): variant is SubscriptionListingVariant {
  return variant.type === "subscription";
}

type ListingAvailabilityQueryParams = {
  month: { year: number; month: number };
  listingId: string;
  variantId: string;
  durationMinutes: number;
  bufferMinutes: number;
};

export const listingVariantAvailabilityQuery = (params: ListingAvailabilityQueryParams) => {
  const timezoneIana = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const monthStart = new Date(params.month.year, params.month.month - 1, 1);
  const monthEnd = new Date(params.month.year, params.month.month, 1);

  return queryOptions({
    queryKey: ["listing-availability", params],
    queryFn: async () => {
      return await api.listings.getListingVariantAvailability({
        listingId: params.listingId,
        variantId: params.variantId,
        tz: timezoneIana,
        from: monthStart.toISOString(),
        to: monthEnd.toISOString(),
        duration: params.durationMinutes,
        buffer: params.bufferMinutes,
      });
    },
  });
};

export function usePostListingEnquiryMessageMutation(listingId: string) {
  return useMutation({
    mutationFn: async (requestBody?: PostListingEnquiryMessageBody) => {
      return await api.listings.postListingEnquiryMessage({ listingId, requestBody });
    },
  });
}
