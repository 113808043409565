import { myStateQuery, useUpdateMyStateMutation } from "@/api/me";
import { Button } from "@/components/ui/Button";
import { Box, Heading, Img, Text } from "@givenwell/components";
import { keyframes, styled } from "@givenwell/stitches";
import { colors, easing } from "@givenwell/theme";
import * as BaseDialog from "@radix-ui/react-dialog";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import receipts from "./claims/receipts.svg";

const CLAIMS_RELEASE_DATE = "2025-02-10";

export function WhatsNewDialog() {
  const [open, setOpen] = useState(false);
  const [hasCheckedForNotices, setHasCheckedForNotices] = useState(false);

  const { data } = useQuery(myStateQuery);
  const update = useUpdateMyStateMutation();

  useEffect(() => {
    if (hasCheckedForNotices) return;
    if (!data) return;
    if (!open && data.lastSeenUpdateNotice < CLAIMS_RELEASE_DATE) {
      setOpen(true);
    }
    setHasCheckedForNotices(true);
  }, [data, open, hasCheckedForNotices]);

  return (
    <BaseDialog.Root
      open={open}
      onOpenChange={open => {
        setOpen(open);
        if (!open) update.mutate({ lastSeenUpdateNotice: CLAIMS_RELEASE_DATE });
      }}
    >
      <BaseDialog.Portal>
        <DialogOverlay></DialogOverlay>
        <DialogContent>
          <DialogCard>
            <Box css={{ flex: "1 0 0px" }}>
              <Box css={{ height: 200, position: "relative" }}>
                <Img
                  src={receipts}
                  css={{ size: 256, pos: "absolute", top: -84, left: "50%", transform: "translateX(-50%)" }}
                />
              </Box>
              <Heading css={{ textAlign: "center", fontScale: "3xl", mb: 16 }}>Introducing Claims</Heading>
              <Text css={{ textAlign: "center", textWrap: "pretty" }}>
                Want something that’s not in our marketplace? Now you can send us a receipt, and we’ll pay you back from
                your token balance.
              </Text>
            </Box>
            <Box css={{ mt: 24 }}>
              <BaseDialog.Close asChild>
                <Button variant="filled" color="primary" size="lg" css={{ width: "100%" }}>
                  Cool!
                </Button>
              </BaseDialog.Close>
            </Box>
          </DialogCard>
        </DialogContent>
      </BaseDialog.Portal>
    </BaseDialog.Root>
  );
}

const overlayOpen = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const overlayClose = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
});

export const DialogOverlay = styled(BaseDialog.Overlay, {
  position: "fixed",
  zIndex: 2147483647,
  backgroundColor: colors.blue900 + "AA",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  "&[data-state='open']": {
    animation: `${overlayOpen()} 400ms ${easing.standard}`,
  },
  "&[data-state='closed']": {
    animation: `${overlayClose()} 400ms ${easing.standard}`,
  },
});

const contentOpen = keyframes({
  from: {
    transform: "translateY(100%)",
  },
  to: {
    transform: "translateY(0)",
  },
});

const contentClose = keyframes({
  from: {
    transform: "translateY(0)",
  },
  to: {
    transform: "translateY(100%)",
  },
});

export const DialogContent = styled(BaseDialog.Content, {
  position: "fixed",
  zIndex: 2147483647,
  bottom: 0,
  left: 0,
  right: 0,

  "&[data-state='open']": {
    animation: `${contentOpen()} 400ms ${easing.standard}`,
  },
  "&[data-state='closed']": {
    animation: `${contentClose()} 400ms ${easing.standard}`,
  },

  "@sm": {
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&[data-state='open']": {
      animation: `${overlayOpen()} 400ms ${easing.standard}`,
    },
    "&[data-state='closed']": {
      animation: `${overlayClose()} 400ms ${easing.standard}`,
    },
  },
});

const DialogCard = styled("div", {
  backgroundColor: "white",
  roundedT: 12,
  padding: 16,
  display: "flex",
  flexDirection: "column",
  pb: "max(16px, var(--safe-area-inset-bottom))",
  "@sm": {
    width: "100%",
    maxWidth: 500,
    rounded: 12,
  },
});
